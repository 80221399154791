// extracted by mini-css-extract-plugin
export var alignLeft = "w_qp d_bG";
export var alignCenter = "w_bP d_bD";
export var alignRight = "w_qq d_bH";
export var textAlignLeft = "w_tr";
export var textAlignCenter = "w_ts";
export var textAlignRight = "w_tt";
export var hoursInnerWrapperAlt = "w_tv d_fb d_P d_Z d_bz d_bD d_bP d_dj";
export var hoursInnerWrapperLeft = "w_tw d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5 d_dj";
export var hoursText = "w_tx d_dw";
export var titleMargin = "w_qL d_cw";
export var hoursInnerInnerWrapper = "w_ty d_cz";