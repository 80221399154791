// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "x_tz d_bD";
export var storyRowWrapper = "x_hx d_hx d_bK";
export var storyLeftWrapper = "x_tB d_bz d_bP";
export var storyWrapperFull = "x_tC d_cD";
export var storyWrapperFullLeft = "x_pl d_cD d_bz d_bP d_bD";
export var contentWrapper = "x_mv d_hy";
export var storyLeftWrapperCenter = "x_tD d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "x_tF d_hF";
export var storyHeader = "x_tG d_hD d_w d_cs";
export var storyHeaderCenter = "x_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "x_hB d_hB d_by d_dw";
export var storyBtnWrapper = "x_tH d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "x_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "x_qH d_fg d_Z";
export var imageWrapperFull = "x_qJ d_w d_H d_bf d_Z";
export var SubtitleSmall = "x_qd t_qd t_rT t_r4";
export var SubtitleNormal = "x_qf t_qf t_rT t_r5";
export var SubtitleLarge = "x_qg t_qg t_rT t_r6";
export var textLeft = "x_dv";
export var textCenter = "x_dw";
export var textRight = "x_dx";